import { SubmitButton } from '@volvo-cars/react-forms';
import { Message, Spacer } from 'vcc-ui';

type ModalButton = {
  text: string;
  dataColor?: 'accent' | 'destructive' | 'neutral';
  onClick: () => void;
  disabled?: boolean;
};

type ModalButtonsProps = {
  closeButton: ModalButton;
  acceptButton?: ModalButton;
  isLoading?: boolean;
  errorMessage?: string;
};

const ModalButtons: React.FC<ModalButtonsProps> = ({
  closeButton,
  acceptButton,
  isLoading,
  errorMessage,
}) => (
  <>
    <Spacer size={errorMessage ? 3 : 5} />
    {errorMessage && (
      <>
        <Message type="error">{errorMessage}</Message>
        <Spacer />
      </>
    )}

    <div className="button-group justify-center">
      {acceptButton && (
        <SubmitButton
          type="button"
          onClick={acceptButton.onClick}
          color={acceptButton.dataColor}
          loading={isLoading}
          disabled={acceptButton.disabled}
        >
          {acceptButton.text}
        </SubmitButton>
      )}
      <button
        type="button"
        className="button-outlined"
        onClick={closeButton.onClick}
      >
        {closeButton.text}
      </button>
    </div>
  </>
);

export default ModalButtons;
