import dynamic from 'next/dynamic';

import { useUserContext } from '@collab/features/user';
import { ModalButtons } from '@collab/organisms';

import useLogin from 'libs/users/useLogin';

const PopupContainer = dynamic(
  () => import('@collab/features/popup/PopupContainer'),
);

const ErrorPopup: React.FC<{
  isOpen: boolean;
  close: () => void;
}> = ({ isOpen, close }) => {
  const { isUserStatusLoading } = useUserContext();
  const login = useLogin();

  return (
    <PopupContainer
      open={isOpen}
      onClose={close}
      title="Your session has ended"
    >
      <>
        <p className="font-16">Would you like to log in again?</p>
        <ModalButtons
          isLoading={isUserStatusLoading}
          acceptButton={{
            text: 'Sign in',
            onClick: () => login(),
          }}
          closeButton={{ text: 'Close', onClick: close }}
        />
      </>
    </PopupContainer>
  );
};

export default ErrorPopup;
